<template>
    <div>
        <PartsTable />
    </div>
</template>

<script>
import PartsTable from '../components/PartsTable'
export default {
    name: 'Parts',
    components: {PartsTable}
}
</script>