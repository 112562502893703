<template>
    <v-container fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card>
                    <v-card-title>
                        <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
                            <v-icon left large>mdi-chevron-left</v-icon>
                        </v-btn>
                        <h3>{{ isEditMode ? "Update" : "New" }} Part</h3>
                        
                        </v-card-title>
                    <!--New Material Type form -->
                    <v-form @submit.prevent="savePart" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <!--Part name-->
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="part_name"
                                        label="Part Name"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Material -->
                            <v-row dense>
                                <v-col cols="6" sm="6" md="6">
                                    <v-autocomplete
                                        v-model="part_material"
                                        label="Select Material"
                                        :items="material_items"
                                        item-text="material_item_name"
                                        return-object
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <!--Part Number-->
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field
                                        readonly
                                        v-model="part_number"
                                        label="Part #"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <!--Revision Number-->
                                <v-col cols="2" sm="2" md="2">
                                    <v-text-field
                                        readonly
                                        v-model="part_revision_number"
                                        label="Revision #"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Work Center -->
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <h4>Related Work Center</h4>
                                </v-col>
                             </v-row>
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete
                                        v-model="part_work_center"
                                        :items="workCenterList"
                                        item-text="work_center_name"
                                        return-object
                                        label="Related Work Center"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    ></v-autocomplete>
                                </v-col>
                             </v-row>
                            <!-- Dimensions -->
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <h4>Dimensions</h4>
                                </v-col>
                             </v-row>
                            <v-row dense>
                                <!--Unit of measurement-->
                                <v-col cols="3" sm="3" md="3">
                                    <v-select
                                        v-model="part_uom"
                                        :items="unitsOfMeasurement"
                                        item-text="unit_of_measurement_name"
                                        item-value="unit_of_measurement_symbol"
                                        label="Unit of measurement"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="3" sm="3" md="3">
                                    <v-text-field
                                        v-model.number="part_thickness"
                                        type="number"
                                        label="Thickness"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3" sm="3" md="3">
                                    <v-text-field
                                        v-model.number="part_width"
                                        type="number"
                                        label="Width"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3" sm="3" md="3">
                                    <v-text-field
                                        v-model.number="part_length"
                                        type="number"
                                        label="Length"
                                        required
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn to='/parts-assemblies' color="#ce2458" text x-large>
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn @click="savePart" color="#33cc99" text x-large :disabled="!valid" :loading="loading">
                            Submit
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewPart',
    mixins: [mixin_UserRoles],

    data(){
        return{
            isEditMode: false,
            valid: null,
            part_id: null,
            part_number: null,
            part_revision_number: 0,
            part_name: null,
            part_work_center: null,
            part_thickness: null,
            part_width: null,
            part_length: null,
            part_material: null,
            part_uom: null,
            part_date_added: null,
            part_timestamp: null,
            part_created_by_id: null,
            part_created_by_name: null,            
            material_items: [],
            loading: false,
            unitsOfMeasurement: [],
            workCenterList: [],
        }
    },
    store,
    computed: {
        partId() {
            return this.$route.params.part_id;
        }
    },
    created(){
        if (this.partId) {
            this.isEditMode = true;
            this.loadPartDetails(this.partId);
        } else {
            this.isEditMode = false;
            this.getPartCount(); // Get a new part number only when adding
        }
        //Captures user that created the part
        this.part_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.part_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.part_created_by_name = doc.data().displayName
            })
        }),
        this.getMaterialItems();
        this.getUnitsOfMeasurement();
        this.getWorkCenters();
    },
    methods: {
        //Reroute to datatable
        routeToTable(){
            this.loading = false
            showSnackbar("New part added successfully");
            this.$router.push('/parts-assemblies')
        },
        //
        async getUnitsOfMeasurement() {
            const docRef = db.collection("units_of_measurement").orderBy("unit_of_measurement_name");

            try {
                const snapshot = await docRef.get();
                this.unitsOfMeasurement = snapshot.docs.map(doc => doc.data());
            } catch (error) {
                console.error(error);
            }
        },
        async savePart () {
            if(!this.$refs.form.validate()) return;

            try {
                this.loading = true

                const docRef = this.isEditMode
                    ? db.collection('parts').doc(this.partId)
                    : db.collection('parts').doc();
                
                const partId = this.isEditMode ? this.partId : docRef.id;
                const revisionNumber = this.isEditMode ? this.part_revision_number + 1 : this.part_revision_number;

                await docRef.set({
                    part_id: partId,
                    part_number: this.part_number,
                    part_revision_number: revisionNumber,
                    part_name: this.part_name,
                    part_work_center: this.part_work_center,
                    part_uom: this.part_uom,
                    part_thickness: this.part_thickness,
                    part_width: this.part_width,
                    part_length: this.part_length,
                    part_material: this.part_material,
                    part_date_added: new Date().toISOString().slice(0, 10),
                    part_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    part_created_by_id: this.part_created_by_id,
                    part_created_by_name: this.part_created_by_name,
                }, { merge: true });
                
                if(!this.isEditMode) {
                    this.incrementPartCount() 
                }
                showSnackbar(this.isEditMode ? "Part updated successfully" : "New part created successfully");
            } catch (error) {
                console.log(error);
            } finally {
                this.routeToTable();
                this.loading = false;
            }
        },
        //
        async getMaterialItems() {
            try {
                this.loading_material_items = true;
                const docRef = db.collection('material_items').orderBy('material_item_name');
                const snapshot = await docRef.get();
                this.material_items = snapshot.docs.map(doc => doc.data());
                this.loading_material_items = false;

            } catch (error) {
                throw new Error(error);
            }
        },
        //
        getPartCount() {
            const docRef = db.collection('part_counter').doc('jFIHsadNMzkkEArJO0EB');
            docRef.onSnapshot((snapshot) => {
                const { part_count } = snapshot.data();
                const formattedCount = `${part_count + 1}`.padStart(4, '0');
                this.part_number = `PART-${formattedCount}`;
                this.loading = false;
            });
        },
        // 
        incrementPartCount() {
            try {
                const docRef = db.collection('part_counter').doc('jFIHsadNMzkkEArJO0EB');
                const increment = firebase.firestore.FieldValue.increment(1);
                docRef.update({ part_count: increment });
            } catch(error) {
                console.error(error);
            }
        },
        //
        async getWorkCenters() {
            const docRef = db.collection("work_centers").orderBy("work_center_name");
            try {
                const snapshot = await docRef.get();
                this.workCenterList = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const { work_center_name, work_center_id } = data;
                    return { work_center_name, work_center_id };
                });
            } catch (error) {
                console.error(error);
            }
        },
        //
        async loadPartDetails(partId) {
            const docRef = db.collection('parts').doc(partId);
            const doc = await docRef.get();
            if (doc.exists) {
                const partData = doc.data();

                this.part_name = partData.part_name || "";
                this.part_number = partData.part_number;
                this.part_revision_number = partData.part_revision_number || 0;
                this.part_work_center = partData.part_work_center || {};
                this.part_thickness = partData.part_thickness;
                this.part_width = partData.part_width;
                this.part_length = partData.part_length;
                this.part_material = partData.part_material;
                this.part_uom = partData.part_uom;
                this.part_date_added = partData.part_date_added;
                this.part_timestamp = partData.part_timestamp;
                this.part_created_by_id = partData.part_created_by_id;
                this.part_created_by_name = partData.part_created_by_name;
            } else {
                console.error("No such part!");
            }
        },
    }
}
</script>