<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Parts & Assemblies</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <NewPartButton v-if="!firstLoad" />
            <v-spacer />
          </v-card-title>
        </v-col>
      </v-row>

      <!--Navigation Tabs-->
      <v-sheet elevation="4">
        <v-tabs background-color="#6666cf" center-active dark>
          <v-tab>Parts</v-tab>
          <v-tab>Assemblies</v-tab>

          <!--Data tables-->
          <!--Material Items-->
          <v-tab-item>
            <!--Table skeleton loader-->
            <v-row v-if="firstLoad">
              <v-col>
                <v-sheet elevation="4">
                  <v-skeleton-loader
                    :loading="loading_user"
                    type="table"
                    max-height="290"
                  >
                  </v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <!--End of skeleton loader-->
            <v-row
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('work_orders_read')"
            >
              <v-col>
                <!--Parts data table-->
                <v-card>
                  <v-card-title> Parts<v-spacer></v-spacer> </v-card-title>
                  <v-data-table
                    :headers="partHeaders"
                    :items="parts"
                    :search="search"
                    :items-per-page="1000"
                    sort-by="part_material"
                  >
                    <!--Filter orders by job status-->
                    <template v-slot:top>
                      <v-container fluid>
                        <v-row>
                          <v-col lg="3" md="3">
                            <v-autocomplete
                              :items="material_categories"
                              v-model="materialCategorySelection"
                              label="Material Category"
                              item-text="material_category_name"
                              outlined
                              flat
                              solo
                              dense
                              append-icon="mdi-filter-variant"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-spacer />
                          <!--Search Bar-->
                          <v-col lg="6" md="6">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>

                    <!--Edit Part icon button-->
                    <template
                      v-slot:item.part_id="{ item }"
                      v-if="
                        this.user_roles_list
                          .flat()
                          .includes('work_orders_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-part',
                          params: { part_id: item.part_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <!--View part -->
                    <template v-slot:item.part_number="{ item }">
                      <router-link
                        :to="{
                          name: 'view-part',
                          params: { part_id: item.part_id },
                        }"
                      >
                        {{ item.part_number }}
                      </router-link>
                    </template>
                    <!--View material item-->
                    <template v-slot:item.part_material_item_code="{ item }">
                      <router-link
                        :to="{
                          name: 'view-material-item',
                          params: {
                            material_item_id: item.part_material_item_id,
                          },
                        }"
                      >
                        {{ item.part_material_item_code }}
                      </router-link>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <!--Assemblies-->
            <!--Table skeleton loader-->
            <v-row v-if="firstLoad">
              <v-col>
                <v-sheet elevation="4">
                  <v-skeleton-loader
                    :loading="loading_user"
                    type="table"
                    max-height="290"
                  >
                  </v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <!--End of skeleton loader-->
            <v-row
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('work_orders_read')"
            >
              <v-col>
                <!--Materials data table-->
                <v-card>
                  <v-card-title>
                    Assemblies<v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    class="assembly_part_datatable"
                    :headers="assembliesHeaders"
                    item-key="assembly_id"
                    :items="assemblies"
                    :search="search"
                    :items-per-page="100"
                    show-expand
                  >
                    <!--Edit Material Category icon button-->
                    <template
                      v-slot:item.assembly_id="{ item }"
                      v-if="
                        this.user_roles_list
                          .flat()
                          .includes('work_orders_write')
                      "
                    >
                      <v-btn
                        :to="{
                          name: 'edit-assembly',
                          params: { assembly_id: item.assembly_id },
                        }"
                        color="#3d2cdd"
                        icon
                        small
                        ><v-icon small>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <!--Expand assembly to view parts-->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-data-table
                          :headers="partExpansionHeaders"
                          :items="item.assembly_parts"
                          :items-per-page="1000"
                          hide-default-footer
                          class="row_grey_background"
                        >
                          <!--View part -->
                          <template v-slot:item.part_number="{ item }">
                            <router-link
                              :to="{
                                name: 'view-part',
                                params: { part_id: item.part_id },
                              }"
                            >
                              {{ item.part_number }}
                            </router-link>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import NewPartButton from "../components/NewPartButton";
import store from "../store/store";
import { mapState } from "vuex";

export default {
  name: "WorkOrdersTable",
  mixins: [mixin_UserRoles],
  components: { NewPartButton },
  data() {
    return {
      search: "",
      parts: [],
      assemblies: [],
      material_categories: [],
      materialCategorySelection: "",
      partHeaders: [
        { text: 'Edit', value: 'part_id', align: 'start' },
        { text: 'Part #', value: 'part_number' },
        { text: 'Name', value: 'part_name' },
        { text: 'Work center', value: 'part_work_center.work_center_name' },
        { text: 'Uom', value: 'part_uom' },
        { text: 'Material', value: 'part_material.material_item_name' },
        { text: 'Thickness', value: 'part_thickness' },
        { text: 'Width', value: 'part_width' },
        { text: 'Length', value: 'part_length' },
      ],
      assembliesHeaders: [
        { text: 'Edit', value: 'assembly_id', align: 'start' },
        { text: 'Assembly #', value: 'assembly_number' },
        { text: 'Name', value: 'assembly_name' },
        { text: 'Effective Date', value: 'assembly_effective_date' },
        { text: 'Revision #', value: 'assembly_revision_number' },
        { text: '', value: 'data-table-expand' },
      ],
      partExpansionHeaders: [
        { text: 'Part #', value: 'part_number' },
        { text: 'Name', value: 'part_name' },
        { text: 'Work center', value: 'part_work_center.work_center_name' },
        { text: 'Uom', value: 'part_uom' },
        { text: 'Material', value: 'part_material.material_item_name' },
        { text: 'Thickness', value: 'part_thickness' },
        { text: 'Width', value: 'part_width' },
        { text: 'Length', value: 'part_length' },
        { text: 'Quantity', value: 'part_quantity' },
        { text: '', value: 'data-table-expand' },
      ]
    }
  },
  store,
  computed: mapState({
    //Filter parts list by material category
    filteredItems() {
      return this.parts.filter((i) => {
        return (
          this.materialCategorySelection === "" ||
          i.part_material_category === this.materialCategorySelection
        );
      });
    },
  }),
  created() {
    //Get material items from DB
    db
      .collection("parts")
      .orderBy("part_name")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          //Pushes the customers name to the empty customers array in 'data' above
          this.parts.push(data);
        });
      }),
      //Get assemblies items from DB
      db
        .collection("assemblies")
        .orderBy("assembly_name")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.assemblies.push(data);
          });
        }),
      //Get material categories from DB (used to filter parts)
      db
        .collection("material_categories")
        .orderBy("material_category_type")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            //Pushes the products name to the empty customers array in 'data' above
            this.material_categories.push(data);
          });
        });
  },
};
</script>

<style scoped>
.row_grey_background {
  background: #eff0f0 !important;
}
.assembly_part_datatable td {
  padding: 0px !important;
  padding-left: 50px !important;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>