//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
}

//DATA TABLE HEADERS
//Work Orders table
const workOrdersTableHeaders = [
    { text: 'Edit', value: 'work_order_id', align: 'start' },
    { text: 'Work Order #', sortable: true, value: 'work_order_number' },
    { text: 'Type', value: 'work_order_type' },
    { text: 'Date Created', value: 'work_order_date_created' },
    { text: 'Production Status', value: 'work_order_production_status' },
    { text: 'Deadline', value: 'work_order_deadline' },
    { text: 'Progress', value: 'work_order_progress' },
]
const materialItemsHeaders = [
    { text: 'Edit', value: 'material_item_id', align: 'start' },
    { text: 'Code', value: 'material_item_code' },
    { text: 'Item Name', value: 'material_item_name' },
    { text: 'Display Name', value: 'material_item_display_name' },
    { text: 'Prefix', value: 'material_item_prefix' },
    { text: 'Category', value: 'material_item_category' },
    { text: 'Material Type', value: 'material_item_type' },
    { text: 'UoM', value: 'material_item_unit_of_measurement' },
    { text: 'Date Added', value: 'material_item_date_added' },
]
const materialCategoriesHeaders = [
    { text: 'Edit', value: 'material_category_id', align: 'start' },
    { text: 'Category Name', value: 'material_category_name' },
    { text: 'Prefix', value: 'material_category_prefix' },
    { text: 'Material Type', value: 'material_category_type' },
    { text: 'Date Added', value: 'material_category_date_added' },
]
const materialTypesHeaders = [
    { text: 'Edit', value: 'material_type_id', align: 'start' },
    { text: 'Type Name', value: 'material_type_name' },
    { text: 'Date Added', value: 'material_type_date_added' },
]
const unitsOfMeasurementHeaders = [
    { text: 'Edit', value: 'unit_of_measurement_id', align: 'start' },
    { text: 'Name', value: 'unit_of_measurement_name' },
    { text: 'Symbol', value: 'unit_of_measurement_symbol' },
]
const assembliesHeaders = [
    { text: 'Edit', value: 'assembly_id', align: 'start' },
    { text: 'Assembly #', value: 'assembly_number' },
    { text: 'Name', value: 'assembly_name' },
    { text: 'Date Added', value: 'assembly_date_added' },
    { text: '', value: 'data-table-expand' },
]
const partExpansionHeaders = [
    { text: 'Part #', value: 'part_number' },
    { text: 'Part Name', value: 'part_name' },
    { text: 'Material Category', value: 'part_material_category' },
    { text: 'Quantity', value: 'part_quantity' },
    { text: '', value: 'data-table-expand' },
]
const partSelectionHeaders = [
    { text: 'Part #', value: 'part_number', class: "part_table_header" },
    { text: 'Part Name', value: 'part_name' },
    { text: 'Material Category', value: 'part_material_category' },
    { text: 'Quantity', value: 'part_quantity' },
]
const plantHeaders = [
    { text: 'Edit', value: 'plant_id', align: 'start' },
    { text: 'Plant Code', value: 'plant_code' },
    { text: 'Name', value: 'plant_name' },
    { text: 'Description', value: 'plant_description' },
    { text: 'Date Added', value: 'plant_date_added' },
    { text: 'Status', value: 'plant_status' },
]
const workCenterGroupHeaders = [
    { text: 'Edit', value: 'work_center_group_id', align: 'start' },
    { text: 'Work Center Group #', value: 'work_center_group_number' },
    { text: 'Name', value: 'work_center_group_name' },
    { text: 'Related Plant', value: 'work_center_group_plant' },
    { text: 'Date Added', value: 'work_center_group_date_added' },
    { text: 'Status', value: 'work_center_group_status' },
]
const workCenterHeaders = [
    { text: 'Edit', value: 'work_center_id', align: 'start' },
    { text: 'Work Center #', value: 'work_center_number' },
    { text: 'Name', value: 'work_center_name' },
    { text: 'Group', value: 'work_center_group' },
    { text: 'Related Plant', value: 'work_center_plant_name' },
    { text: 'Date Added', value: 'work_center_date_added' },
    { text: 'Status', value: 'work_center_status' },
]
const machineHeaders = [
    { text: 'Edit', value: 'machine_id', align: 'start' },
    { text: 'Machine #', value: 'machine_number' },
    { text: 'Machine Name', value: 'machine_name' },
    { text: 'Related Work Center', value: 'machine_work_center' },
    { text: 'Date Added', value: 'machine_date_added' },
    { text: 'Status', value: 'machine_status' },
]
const machineSelectionHeaders = [
    { text: 'Machine #', value: 'machine_number' },
    { text: 'Machine Name', value: 'machine_name' },
]
const employeeSelectionHeaders = [
    { text: 'Employee #', value: 'employee_number' },
    { text: 'Employee Name', value: 'employee_fullName' },
    { text: 'Job Description', value: 'employee_jobDescription' }
]
const productionInstructionHeaders = [
    { text: 'Work Order #', value: 'instruction_number' },
    { text: 'Work Center', value: 'work_center' },
    { text: 'Type', value: 'instruction_type' },
    { text: 'Date Issued', value: 'instruction_date_issued' },
    { text: 'Status', value: 'instruction_status' },
    { text: 'Deadline', value: 'instruction_deadline' },
    { text: 'Actions', value: 'instruction_id' },
]
const partProductionSelectionHeaders = [
    { text: 'Part #', value: 'part_number', class: "part_table_header" },
    { text: 'Part Name', value: 'part_name' },
    { text: 'Material Category', value: 'part_material_category' },
    { text: 'QTY Required', value: 'part_quantity_required' },
    { text: 'QTY Completed', value: 'part_quantity_completed' },
]
const completedProductionInstructionHeaders = [
    { text: 'Work Order #', value: 'instruction_number' },
    { text: 'Work Center', value: 'work_center' },
    { text: 'Type', value: 'instruction_type' },
    { text: 'Date Issued', value: 'instruction_date_issued' },
    { text: 'Deadline', value: 'instruction_deadline' },
    { text: 'Date Completed', value: 'instruction_date_completed' }
]
const assemblyProductionSelectionHeaders = [
    { text: 'Assembly #', value: 'assembly_number' },
    { text: 'Assembly Name', value: 'assembly_name' },
    { text: 'QTY Required', value: 'assembly_quantity_required' },
    { text: 'QTY Completed', value: 'assembly_quantity_completed' },
    {},
]

//----------------------------------------------
//FORM HEADERS
//Add Work Order Form Header
const newWorkOrderFormHeader = {
    form_header_title: 'Create New Work Order',
    form_header_to: '/work-orders',
}
//Edit Work Order Form Header
const editWorkOrderFormHeader = {
    form_header_title: 'Edit Work Order',
    form_header_to: '/work-orders',
}
//New Material Type Form Header
const newMaterialTypeHeader = {
    form_header_title: 'Add New Material Type',
    form_header_to: '/materials',
}
//Edit Material Type Form Header
const editMaterialTypeHeader = {
    form_header_title: 'Edit Material Type',
    form_header_to: '/materials',
}
//New Material Categories Form Header
const newMaterialCategoryHeader = {
    form_header_title: 'Add New Material Category',
    form_header_to: '/materials',
}
//Edit Material Categories Form Header
const editMaterialCategoryHeader = {
    form_header_title: 'Edit Material Category',
    form_header_to: '/materials',
}
//New Material Item Form Header
const newMaterialItemHeader = {
    form_header_title: 'Add New Material Item',
    form_header_to: '/materials',
}
//Edit Material Item Form Header
const editMaterialItemHeader = {
    form_header_title: 'Edit Material Item',
    form_header_to: '/materials',
}
//New Unit of Measurement Form Header
const newUnitOfMeasurementHeader = {
    form_header_title: 'Add New Unit of Measurement',
    form_header_to: '/units-of-measurement',
}
//Edit Unit of Measurement Form Header
const editUnitOfMeasurementHeader = {
    form_header_title: 'Edit Unit of Measurement',
    form_header_to: '/units-of-measurement',
}
//New Part Form Header
const newPartHeader = {
    form_header_title: 'Add New Part',
    form_header_to: '/parts-assemblies',
}
//Edit Part Form Header
const editPartHeader = {
    form_header_title: 'Edit Part',
    form_header_to: '/parts-assemblies',
}
//New Assembly Form Header
const newAssemblyHeader = {
    form_header_title: 'Create New Assembly',
    form_header_to: '/parts-assemblies',
}
//Edit Assembly Form Header
const editAssemblyHeader = {
    form_header_title: 'Edit Assembly',
    form_header_to: '/parts-assemblies',
}
//New Plant Form Header
const newPlantHeader = {
    form_header_title: 'Add New Plant',
    form_header_to: '/plants-work-centers',
}
//Edit Plant Form Header
const editPlantHeader = {
    form_header_title: 'Edit Plant',
    form_header_to: '/plants-work-centers',
}
//New Work Center Group Form Header
const newWorkCenterGroupHeader = {
    form_header_title: 'Add New Work Center Group',
    form_header_to: '/plants-work-centers',
}
//Edit Work Center Group Form Header
const editWorkCenterGroupHeader = {
    form_header_title: 'Edit Work Center Group',
    form_header_to: '/plants-work-centers',
}
//New Work Center Form Header
const newWorkCenterHeader = {
    form_header_title: 'Add New Work Center',
    form_header_to: '/plants-work-centers',
}
//Edit Work Center Form Header
const editWorkCenterHeader = {
    form_header_title: 'Edit Work Center',
    form_header_to: '/plants-work-centers',
}

//New Machine Form Header
const newMachineFormHeader = {
    form_header_title: 'Add New Machine',
    form_header_to: '/machines',
}
//New Machine Form Header
const editMachineFormHeader = {
    form_header_title: 'Edit Machine',
    form_header_to: '/machines',
}
//New Part production instruction
const newPartProductionInstructionHeader = {
    form_header_title: 'Create New Instruction (Make Parts)',
    form_header_to: '/production-instructions',
}
//Edit Part production instruction
const editPartProductionInstructionHeader = {
    form_header_title: 'Edit Instruction (Make Parts)',
    form_header_to: '/production-instructions',
}
//New Assembly Production Instruction
const newAssemblyProductionInstructionHeader = {
    form_header_title: 'Create New Instruction (Assemble Parts)',
    form_header_to: '/production-instructions',
}
//Edit Assembly Production Instruction
const editAssemblyProductionInstructionHeader = {
    form_header_title: 'Edit Instruction (Assemble Parts)',
    form_header_to: '/production-instructions',
}


//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA,

    //DATA TABLE HEADERS
    workOrdersTableHeaders, materialItemsHeaders, materialCategoriesHeaders,
    materialTypesHeaders, unitsOfMeasurementHeaders,
    assembliesHeaders, partExpansionHeaders, partSelectionHeaders,
    plantHeaders, workCenterGroupHeaders, workCenterHeaders,
    machineHeaders, machineSelectionHeaders,
    employeeSelectionHeaders,
    productionInstructionHeaders, partProductionSelectionHeaders, completedProductionInstructionHeaders,
    assemblyProductionSelectionHeaders,

    //FORM HEADERS
    newWorkOrderFormHeader, editWorkOrderFormHeader, newMaterialTypeHeader,
    editMaterialTypeHeader, newMaterialCategoryHeader, editMaterialCategoryHeader,
    newMaterialItemHeader, editMaterialItemHeader,
    newUnitOfMeasurementHeader, editUnitOfMeasurementHeader,
    newPartHeader, editPartHeader, newAssemblyHeader, editAssemblyHeader,
    newPlantHeader, editPlantHeader, newWorkCenterGroupHeader, editWorkCenterGroupHeader,
    newWorkCenterHeader, editWorkCenterHeader,
    newMachineFormHeader, editMachineFormHeader,
    newPartProductionInstructionHeader, editPartProductionInstructionHeader,
    newAssemblyProductionInstructionHeader, editAssemblyProductionInstructionHeader,
}