<!-- PartDialog.vue -->
<template>
    <v-dialog v-model="dialog" persistent max-width="1050px">
      <v-card>
        <v-card-title>
          Select Parts
        </v-card-title>
        <v-card-text>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Parts"
                outlined
                dense
                hide-details
            ></v-text-field>
            <v-data-table
                :headers="partSelectionHeaders"
                :items="filteredParts"
                :items-per-page="500"
                hide-default-footer
            >
                <!--Quantity-->
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.part_number }}</td>
                        <td>{{ item.part_material.material_item_name }}</td>
                        <td>{{ item.part_name }}</td>
                        <td>{{ item.part_uom }}</td>
                        <td>{{ item.part_thickness }}</td>
                        <td>{{ item.part_width }}</td>
                        <td>{{ item.part_length }}</td>
                        <td>
                            <v-text-field
                                v-model="item.part_quantity"
                                type="number"
                                min="1"
                                solo
                                dense
                                hide-details="auto"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-btn icon @click="addPartToAssembly(item)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Done <v-icon small right>mdi-check</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "PartDialog",
    props: {
      parts: Array,
      assembly_parts: Array,
    },
    data() {
      return {
        dialog: false,
        search: "",
        selectedParts: [],
        partSelectionHeaders:   [
            { text: 'Part #', value: 'part_number', class: "part_table_header" },
            { text: 'Material', value: 'part_material.material_item_name' },
            { text: 'Name', value: 'part_name' },
            { text: 'Uom', value: 'part_uom' },
            { text: 'Thickness', value: 'part_thickness' },
            { text: 'Width', value: 'part_width' },
            { text: 'Length', value: 'part_length' },
            { text: 'Quantity', value: 'part_quantity' },
        ],
      };
    },
    computed: {
        partSelectionList() {
            const selectedPartIds = new Set(this.assembly_parts.map(part => part.part_id));
            return this.parts.filter(part => !selectedPartIds.has(part.part_id)).map(part => ({
                ...part,
                part_quantity: 0
            }));
        },
        filteredParts() {
            const searchLower = this.search.toLowerCase();
            return this.partSelectionList.filter(part => 
                this.searchObject(part, searchLower)
            );
        }
    },
    methods: {
      open() {
        this.dialog = true;
      },
      close() {
        this.dialog = false;
      },
      //
      addPartToAssembly(part) {
        const { part_quantity : quantity } = part;
        if (quantity === 0 || quantity < 0) return false;
        this.$emit('part-added', part);
      },
      //
      searchObject(obj, searchString) {
        return Object.values(obj).some(value => {
          if (typeof value === 'object' && value !== null) {
            // Recursive search in case of nested objects
            return this.searchObject(value, searchString);
          } else {
            return String(value).toLowerCase().includes(searchString);
          }
        });
      }
    },
  };
  </script>
  