<template>
    <v-container  fluid>
            <v-row dense>
                <v-col lg="8" offset-lg="2">
                <v-card-title>
                <v-btn
                    to="/parts-assemblies"
                    color="#ffffff"
                    light
                    elevation="0"
                >
                <v-icon left large>mdi-chevron-left</v-icon>
                </v-btn>
                <h3>Part Datasheet</h3>

            </v-card-title>
                <v-sheet elevation="2" class="pa-5">
                    <v-container>
                        <!--Document title-->
                        <v-row>
                            <v-col>
                                <h1>{{part_number}}</h1>
                            </v-col>
                        </v-row>
                        <!--Company Details-->
                        <v-row>
                            <v-col sm="3" md="3">
                                <v-img
                                    :src="company_logo"
                                    max-width="150"
                                ></v-img>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col sm="3" md="3" class="text-right caption">
                                <v-col>
                                {{company_legal_name}}<br>
                                <span v-if="company_trading_as != null">T/A {{company_trading_as}}</span><br>
                                Reg No. {{company_reg_number}}<br>
                                VAT No. {{company_VAT_number}}
                                </v-col>
                                <v-col>
                                    {{company_physical_address_line_1}}<br>
                                    {{company_physical_address_line_2}}<br>
                                    {{company_physical_address_city}} {{company_physical_address_zip}}<br>
                                </v-col>
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!--Job details-->
                        <v-row>
                            <v-col sm="3" md="3">
                                <h4>Part Number</h4>
                            </v-col>
                            <v-col sm="5" md="5">
                                <h4>Part Name</h4>
                            </v-col>
                            <v-col sm="3" md="3">
                                <h4>Date Added</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3">
                                {{part_number}}
                            </v-col>
                            <v-col sm="5" md="5">
                                {{part_name}}
                            </v-col>
                            <v-col sm="3" md="3">
                                {{part_date_added}}
                            </v-col>
                        </v-row>
                        <v-row class="my-3">
                            <v-col sm="3" md="3">
                                <h4>Description</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3">
                                {{part_description}}
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col sm="3" md="3">
                                <h4>Material Name</h4>
                            </v-col>
                            <v-col sm="9" md="9">
                                {{part_material_item_name}}
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col sm="3" md="3">
                                <h4>Material Category</h4>
                            </v-col>
                            <v-col sm="9" md="9">
                                {{part_material_category}}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3">
                                <h4>Material Type</h4>
                            </v-col>
                            <v-col sm="9" md="9">
                                {{part_material_type}}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3">
                                <h4>Material Code</h4>
                            </v-col>
                            <v-col sm="9" md="9">
                                {{part_material_item_code}}
                            </v-col>
                        </v-row>
                       
                        
                        
                        <!--Edit button-->
                        <v-row>
                            <v-col>
                                <v-card-actions
                                    class="mt-3"
                                    v-if="this.user_roles_list.flat().includes('work_orders_write')"
                                >
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            dark
                                            text
                                            color="#33cc99"
                                            :to="{ name: 'edit-part', params: { part_id: part_id }}"
                                        >
                                            <v-icon left>mdi-pencil</v-icon>
                                            Edit
                                        </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixinCompanyProfile from '../../../globalActions/mixin_CompanyProfile'

export default {
    name: 'ViewMaterialItem',
    mixins: [
        mixin_UserRoles, mixinCompanyProfile
    ],

    data(){
        return{
            part_id: null,
            part_number: null,
            part_name: null,
            part_description: null,
            part_material_item_name: null,
            part_material_category: null,
            part_material_type: null,
            part_material_item_code: null,
            part_date_added: null,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('parts').where('part_id', '==', to.params.part_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.part_id = doc.data().part_id
                    vm.part_number = doc.data().part_number
                    vm.part_name = doc.data().part_name
                    vm.part_description = doc.data().part_description
                    vm.part_material_item_name = doc.data().part_material_item_name
                    vm.part_material_category = doc.data().part_material_category
                    vm.part_material_type = doc.data().part_material_type
                    vm.part_material_item_code = doc.data().part_material_item_code
                    vm.part_date_added = doc.data().part_date_added
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    methods: {
        //Maps database data to template relative to the sales order selected in this route
        fetchData (){
            db.collection('parts').where('part_id', '==', this.$route.params.part_id).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    this.part_id = doc.data().part_id
                    this.part_number = doc.data().part_number
                    this.part_name = doc.data().part_name
                    this.part_description = doc.data().part_description
                    this.part_material_item_name = doc.data().part_material_item_name
                    this.part_material_category = doc.data().part_material_category
                    this.part_material_type = doc.data().part_material_type
                    this.part_material_item_code = doc.data().part_material_item_code
                    this.part_date_added = doc.data().part_date_added
                })
            })
        }
    }
}
</script>