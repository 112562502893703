<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="8" offset-lg="2">
            <v-card-title>
                <v-btn to="/parts-assemblies" color="#ffffff" light elevation="0">
                <v-icon left large>mdi-chevron-left</v-icon>
                </v-btn>
                <h3>{{ isEditMode ? "Update" : "Create New" }} Assembly</h3>
                <!--Show secondary action button-->
                <v-spacer></v-spacer>
                <v-btn
                elevation="2"
                color="secondary"
                light
                class="ml-10"
                v-if="form_secondary_btn_show"
                >
                {{ form_secondary_button_name }}
                <v-icon right>{{ form_secondary_button_icon }}</v-icon>
                </v-btn>
            </v-card-title>
                <v-card>
                <!--New Material Category form -->
                <v-form @submit.prevent="saveAssembly" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <!--Assembly name-->
                            <v-col cols="3" sm="3" md="3">
                                <v-text-field
                                    v-model="assembly_name"
                                    label="Assembly Name"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <!--Assembly number-->
                            <v-col cols="3" sm="3" md="3">
                                <v-text-field
                                    :value="assembly_number"
                                    label="Assembly #"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    readonly
                                >
                                </v-text-field>
                            </v-col>
                            <!--Effective Date-->
                            <v-col cols="4" sm="4" md="4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="assembly_effective_date"
                                            label="Effective Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="assembly_effective_date"
                                        @input="menu = false"
                                        header-color="grey"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- Revision number -->
                            <v-col cols="2" sm="2" md="2">
                                <v-text-field
                                    :value="assembly_revision_number"
                                    label="Revision #"
                                    required
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                    readonly
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <!-- Parts -->
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <h4>Parts List</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!--Empty parts list-->
                                <v-col cols="12" sm="12" md="12" v-if="this.assembly_parts.length === 0">
                                    <v-alert outlined dense icon="mdi-alert-circle" color="grey">
                                        There are no parts assigned to this assembly yet.
                                    </v-alert>
                                </v-col>
                                <!--Included parts list table-->
                                <v-col cols="12" sm="12" md="12">
                                    <v-data-table
                                        :headers="partSelectionHeaders"
                                        :items="assembly_parts"
                                        :items-per-page="500"
                                        hide-default-footer
                                    >
                                        <template v-slot:item.actions="{ item }">
                                            <!-- Delete -->
                                            <v-btn @click="removePartFromAssembly(item.part_id)" icon small><v-icon small color="red">mdi-close</v-icon></v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <!--Add parts button-->
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <v-btn @click="openPartDialog" color="primary">Add Part <v-icon right small>mdi-plus</v-icon></v-btn>

                                    <part-dialog ref="partDialog" :parts="parts" :assembly_parts="assembly_parts" @part-added="handlePartAdded"></part-dialog>
                                </v-col>
                            </v-row>
                        </v-container>
                        <!--End of parts-->
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/parts-assemblies'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Close
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveAssembly"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import PartDialog from '../components/PartDialog.vue';

import store from '../store/store'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewCategory',
    mixins: [mixin_UserRoles],
    components: {
        NoPermissionsCard,
        PartDialog,
    },

    data(){
        return{
            //Initial form values
            isEditMode: false,
            valid: null,
            assembly_id: null,
            assembly_number: null,
            assembly_revision_number: 0,
            assembly_name: null,
            assembly_parts: [],
            assembly_effective_date:  new Date().toISOString().slice(0, 10),
            assembly_date_added:  new Date().toISOString().slice(0, 10),
            assembly_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            assembly_created_by_id: null,
            assembly_created_by_name: null,
            loading: false,
            loading_parts: false,
            parts: [],
            assemblies: [],
            search: '',
            menu: false,

            partSelectionHeaders:   [
                { text: 'Part #', value: 'part_number', class: "part_table_header" },
                { text: 'Material', value: 'part_material.material_item_name' },
                { text: 'Name', value: 'part_name' },
                { text: 'Uom', value: 'part_uom' },
                { text: 'Thickness', value: 'part_thickness' },
                { text: 'Width', value: 'part_width' },
                { text: 'Length', value: 'part_length' },
                { text: 'Quantity', value: 'part_quantity' },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },
    store,
    computed: {
        assemblyId() {
            return this.$route.params.assembly_id; // Assuming 'assembly_id' is the route param
        }
    },
    created(){
        if (this.assemblyId) {
            this.isEditMode = true;
            this.getAssemblyDetails(this.assemblyId);
        } else {
            this.isEditMode = false;
            this.getAssemblyNumber(); // Only get a new number if adding
        }
        //Captures user that created the sales order
        this.captureUserData();
        //Get list of parts from database
        this.getPartsList();
    },
    methods: {
        //Automatically select a row when clicking on the input field
        selectRow(value, row) {
            console.log("value", value)
            row.select(true)
        },
        //Reroute to parts / assemblies list after database is updated
        routeToTable(){
            this.loading = false
            showSnackbar("New assembly created successfully");
            this.$router.push('/parts-assemblies')
        },
        //Update Assembly Number Counter
        increaseAssemblyCounter(){
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1)
            const assemblyNumberCounter = db.collection('assembly_number_counter').doc('cuM3j2pkaAVQxMikrRDr')
            //Increments work order counter
            assemblyNumberCounter.update({ assembly_count: increment })
        },
        //
        async saveAssembly () {         
            if(!this.$refs.form.validate()) return;
            
            this.loading = true
            
            const docRef = this.isEditMode
                ? db.collection('assemblies').doc(this.assemblyId)
                : db.collection('assemblies').doc();
            
            const assemblyId = this.isEditMode ? this.assemblyId : docRef.id;

            const revisionNumber = this.isEditMode ? this.assembly_revision_number + 1 : this.assembly_revision_number;

            try {
                await docRef.set({
                    assembly_id: assemblyId,
                    assembly_number: this.assembly_number,
                    assembly_revision_number: revisionNumber,
                    assembly_name: this.assembly_name,
                    assembly_parts: this.assembly_parts,
                    assembly_date_added: this.assembly_date_added,
                    assembly_effective_date: this.assembly_effective_date,
                    assembly_timestamp: this.assembly_timestamp,
                    assembly_created_by_id: this.assembly_created_by_id,
                    assembly_created_by_name: this.assembly_created_by_name,
                },{ merge: true });

                if(!this.isEditMode) {
                    this.increaseAssemblyCounter() 
                }
                showSnackbar(this.isEditMode ? "Assembly updated successfully" : "New assembly created successfully");
            } catch (error) {
                console.error(error);
            } finally {
                this.routeToTable();
                this.loading = false;
            }
        },
        //
        async getAssemblyDetails(assemblyId) {
            const docRef = db.collection('assemblies').doc(assemblyId);
            const doc = await docRef.get();

            if (doc.exists) {
                const data = doc.data();
                this.assembly_number = data.assembly_number;
                this.assembly_revision_number = data.assembly_revision_number;
                this.assembly_name = data.assembly_name;
                this.assembly_parts = data.assembly_parts || [];
                this.assembly_effective_date = data.assembly_effective_date;
                this.assembly_date_added = data.assembly_date_added;
                this.assembly_timestamp = data.assembly_timestamp;
                this.assembly_created_by_id = data.assembly_created_by_id;
                this.assembly_created_by_name = data.assembly_created_by_name;
            } else {
                console.error("No such assembly!");
            }
        },
        //
        removePartFromAssembly(partId) {
            if(!confirm("Are you sure you want to remove this part from the assembly?")) return;
            const index = this.assembly_parts.findIndex(x => x.part_id === partId);
            this.assembly_parts.splice(index,1);
        },
        async getAssemblyNumber() {
            try {
                const docRef = db.collection('assembly_number_counter').doc('cuM3j2pkaAVQxMikrRDr');
                const snapshot = await docRef.get();
                const { assembly_count } = snapshot.data();
                return this.assembly_number = `ASM-${String(assembly_count).padStart(4,"0")}`;
            } catch (error) {
                console.error(error);
            }
        },
        async getPartsList() {
            try {
                this.loading_parts = true
                const docRef = db.collection('parts');

                const snapshot = await docRef.get();

                this.parts = snapshot.docs.map(doc => ({ ...doc.data() }));

                this.loading_parts = false

            } catch (error) {
                console.error(error);
            }
        },
        //
        async captureUserData() {
            try {
                this.assembly_created_by_id = firebase.auth().currentUser.uid;
                const docRef = db.collection('users').where('uid', '==', this.assembly_created_by_id);
                const snapshot = await docRef.get();
                this.assembly_created_by_name = snapshot.docs[0].data().displayName;
            } catch (error) {
                console.error(error);
            }
        },
        //
        openPartDialog() {
            this.$refs.partDialog.open();
        },
        handlePartAdded(part) {
            this.assembly_parts.push(part);
        },
    }
}
</script>