import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    
} from '../data/data'

Vue.use(Vuex);

import {
    //BUTTONS
    primaryCTA, secondaryCTA,

    //DATA TABLE HEADERS
    workOrdersTableHeaders, materialItemsHeaders, materialCategoriesHeaders, 
    materialTypesHeaders, unitsOfMeasurementHeaders,partHeaders,
    assembliesHeaders, partExpansionHeaders, partSelectionHeaders,
    plantHeaders, workCenterGroupHeaders, workCenterHeaders,
    machineHeaders, machineSelectionHeaders,
    employeeSelectionHeaders,
    productionInstructionHeaders, partProductionSelectionHeaders, completedProductionInstructionHeaders,
    newAssemblyProductionInstructionHeader,

    //FORM HEADERS
    newWorkOrderFormHeader, editWorkOrderFormHeader, newMaterialTypeHeader,
    editMaterialTypeHeader, newMaterialCategoryHeader, editMaterialCategoryHeader,
    newMaterialItemHeader, editMaterialItemHeader,
    newUnitOfMeasurementHeader, editUnitOfMeasurementHeader,
    newPartHeader,editPartHeader, newAssemblyHeader, editAssemblyHeader,
    newPlantHeader, editPlantHeader, newWorkCenterGroupHeader, editWorkCenterGroupHeader,
    newWorkCenterHeader, editWorkCenterHeader,
    newMachineFormHeader, editMachineFormHeader,
    newPartProductionInstructionHeader, editPartProductionInstructionHeader,
    assemblyProductionSelectionHeaders, editAssemblyProductionInstructionHeader,

} from '../data/data'

export default new Vuex.Store({
    state: {
    //BUTTONS
    primaryCTA, secondaryCTA,
    viewWorkOrderScheduleBtn: {
        btn_work_order_schedule_name: 'Work Order Schedule',
        btn_work_order_schedule_to: '/work-order-schedule',
    },
    viewCompletedInstructionsBtn: {
        btn_name: 'View Completed',
        btn_to: '/completed-production-instructions',
    },
    viewActiveInstructionsBtn: {
        btn_name: 'View Active',
        btn_to: '/production-instructions',
    },

    //DATA TABLE HEADERS
    workOrdersTableHeaders, materialItemsHeaders, materialCategoriesHeaders, 
    materialTypesHeaders, unitsOfMeasurementHeaders,partHeaders,
    assembliesHeaders, partExpansionHeaders, partSelectionHeaders,
    plantHeaders, workCenterGroupHeaders, workCenterHeaders,
    machineHeaders, machineSelectionHeaders,
    employeeSelectionHeaders,
    productionInstructionHeaders, partProductionSelectionHeaders, completedProductionInstructionHeaders,
    assemblyProductionSelectionHeaders,

    //FORM HEADERS
    newWorkOrderFormHeader, editWorkOrderFormHeader, newMaterialTypeHeader,
    editMaterialTypeHeader, newMaterialCategoryHeader, editMaterialCategoryHeader,
    newMaterialItemHeader, editMaterialItemHeader,
    newUnitOfMeasurementHeader, editUnitOfMeasurementHeader,
    newPartHeader, editPartHeader, newAssemblyHeader, editAssemblyHeader,
    newPlantHeader, editPlantHeader, newWorkCenterGroupHeader,editWorkCenterGroupHeader,
    newWorkCenterHeader, editWorkCenterHeader,
    newMachineFormHeader, editMachineFormHeader,
    newPartProductionInstructionHeader, editPartProductionInstructionHeader,
    newAssemblyProductionInstructionHeader, editAssemblyProductionInstructionHeader,
    },
    mutations: {},
    actions: {},
    modules: {},
});